import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import Marketplace from "@/views/marketplace/MarketplaceLists";
import store from "@/store"


Vue.use(VueRouter);
Vue.use(Vuex);

// const store2 = new Vuex.Store(
//   {
//       state: {
//           // authenticated: false,
//           token : localStorage.getItem("token")
//       },
//       mutations: {
//           setAuthentication(state, status) {
//               state.token = status;
//           }
//       }
//   }
// );

const routes = [
  {
    path: "/",
    name: "Marketplace",
    component: Marketplace,
    
  },
  {
    path: "/marketplace-details",
    name: "MarketplaceDetails",
    component: () => import(/* webpackChunkName: "MarketplaceDetails" */ '../views/marketplace/MarketplaceDetails.vue')
    // component: MarketplaceDetails,
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: () => import(/* webpackChunkName: "UserProfile" */ '../views/profile/UserProfile.vue')
  },
  {
    path: "/list-upload",
    name: "ListUpload",
    component: () => import(/* webpackChunkName: "ListUpload" */ '../views/marketplace/ListUpload.vue'),
    beforeEnter: (to, from, next) => {
      // console.log(store.state.token)
      if(localStorage.getItem("token") == null) {
          next('/login');
      } else {
          next();
      }
  }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ '../views/profile/Login.vue')
  },
  {
    path:"/addCart",
    name:"AddCart",
component: () => import(/* webpackChunkName: "login" */ '../views/marketplace/AddCart.vue')
  },
  {
    path: "/purchaseList",
    name: "PurchaseList",
    component: () => import(/* webpackChunkName: "PurchaseList" */ '../views/profile/PurchasedList.vue')
  },
];
// console.log(this.$store.getters.getToken)
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  // console.log(store.state.isLogin)
  store.state.from = from.fullPath
  // console.log(to)
  // console.log(from)
  next()
})
export default router;
