import Vue from 'vue'
import Vuex from 'vuex'
// import { axiosHeaderP } from "@/services/axiosinstance";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    from : "",
    user_credit:"",
    cart: [],
    hasGetCart: false,
    notifications: []
    // token : localStorage.getItem("token")
  },
  mutations: {
    setLoggedIn(state, payload){
      state.isLogin = payload
    },
      // console.log(payload)
      setPathFrom(state, path){
        state.from = path
    },

    setUserCredit(state, credit){
      state.user_credit = credit
  },
    updateCart(state, product){
      console.log(product)
      state.cart.push(product)
  },
   updateNotification(state, notification){
     console.log(notification)
     state.notifications = state.notifications.concat(notification)
  },

  },
  actions: {
    setLoggedIn(state, payload){
      state.commit('setLoggedIn', payload)
    },
    setPathFrom(state, path){
      state.commit('setPathFrom', path)
    },
    setUserCredit(state, credit){
      state.commit('setUserCredit', credit)
    },
    updateCart(state, product){
      state.commit('updateCart', product)
    },
    updateNotification(state, notification){
      state.commit('updateNotification', notification)
    },

    // async addToCart(state, payload){
    //   await axiosHeaderP.post(
    //     let formdata = new FormData()

    //   )
    // }

  },
  modules: {
  },
  getters:{

    // getToken (state) {
    //   return state.token
    // },
    getLoggedIn (state) {
      return state.isLogin
    },
    getpathFrom (state) {
      return state.from
    },
    getUserCredit (state) {
      return state.user_credit
    },
    getCart (state) {
      return state.cart
    },
    getNotification (state) {
      return state.notifications
    },
  }
})
