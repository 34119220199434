import Axios from 'axios';

const token = localStorage.getItem('token');


const axiosL = Axios.create({
    // baseURL : 'http://10.10.10.178:8000/'
    // baseURL: 'https://dev.compilerx.com'
    baseURL: process.env.VUE_APP_BASE_URL
})

const axiosHeaderL = Axios.create({
    // baseURL : 'http://10.10.10.178:8000/'
    // baseURL: 'https://dev.compilerx.com/'
    baseURL: process.env.VUE_APP_BASE_URL
})

const axiosP = Axios.create({
    // baseURL: 'http://10.10.10.191:8000/'
    // baseURL : 'https://route.compilerx.com/'
    baseURL: process.env.VUE_APP_ROUTE_BASE_URL

})

const axiosHeaderP = Axios.create({
    // baseURL: 'http://10.10.10.191:8000/' 
    // baseURL : 'https://route.compilerx.com/'
    baseURL: process.env.VUE_APP_ROUTE_BASE_URL

})



//=========Set Default Header============
axiosHeaderP.defaults.headers.common['Authorization'] = token;

axiosHeaderL.defaults.headers.common['Authorization'] ="Bearer "+token;


/**
 * 403  User token Error Handling
 */
axiosHeaderP.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        // if (axiosHeaderP.isCancel(error)) {
        //     return Promise.reject(error);
        // }
        // handle error
        // alert(error.config.url.indexOf('comp_login'));
        if (
            error.response.status == 403
        ) {
            localStorage.clear();
            window.location = "/login";
        }

        throw error;
    }
);

export {
    axiosHeaderL,
    axiosHeaderP,
    axiosL,
    axiosP
}

