<template>
  <div>
    <header class="top__header bg-primary text-white">
      <div class="container-lg">
        <h1 class="main__header mb-2 text-uppercase">
          Millions of CONTACTS At Your Fingertips.
        </h1>
        <p class="txt__sm">
          The Market Place allows you to buy and sell contact lists that have
          been created, cleaned, filtered, and tested.
        </p>
      </div>
      <!-- <div class="col-7 p-0 text-right mt-2 mx-auto">
          <div class="contactActionButtons">
            <router-link
              to="/list-upload"
              class="product__purchase__btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.943"
                height="14.516"
                viewBox="0 0 19.943 14.516"
                class="me-2"
              >
                <g
                  data-name="computing-cloud copy"
                  transform="translate(1.5 1.489)"
                >
                  <g
                    id="Shape"
                    transform="translate(0 0.011)"
                    fill="none"
                    stroke-miterlimit="10"
                  >
                    <path
                      d="M4.235,11.516A4.276,4.276,0,0,1,0,7.2,4.291,4.291,0,0,1,3.776,2.9a5.263,5.263,0,0,1,9.887,1.44,3.593,3.593,0,0,1-.251,7.172Z"
                      stroke="none"
                    />
                    <path
                      d="M 13.41270065307617 11.51640033721924 C 15.3612003326416 11.51640033721924 16.94250106811523 9.904500007629395 16.94250106811523 7.917300224304199 C 16.94250106811523 6.01740026473999 15.49170017242432 4.477499961853027 13.66380023956299 4.344300270080566 C 13.18320083618164 1.867500185966492 11.04120063781738 1.686096169351003e-07 8.471700668334961 1.686096169351003e-07 C 6.431400299072266 1.686096169351003e-07 4.662900447845459 1.180800199508667 3.776400566101074 2.904300212860107 C 1.655100584030151 3.138300180435181 5.340576194612368e-07 4.969799995422363 5.340576194612368e-07 7.198200225830078 C 5.340576194612368e-07 9.584099769592285 1.895400524139404 11.51640033721924 4.235400676727295 11.51640033721924 L 13.41270065307617 11.51640033721924 M 13.41270065307617 13.01640033721924 L 4.235400676727295 13.01640033721924 C 1.072890520095825 13.01640033721924 -1.499999523162842 10.40637016296387 -1.499999523162842 7.198200225830078 C -1.499999523162842 4.494261264801025 0.313761293888092 2.194272518157959 2.830748319625854 1.55478036403656 C 4.088004112243652 -0.346674382686615 6.187225341796875 -1.49999988079071 8.471700668334961 -1.49999988079071 C 11.35958576202393 -1.49999988079071 13.90217304229736 0.3611763715744019 14.86060047149658 3.049917936325073 C 15.70658397674561 3.306333780288696 16.4751033782959 3.785242080688477 17.08975028991699 4.44972038269043 C 17.96209144592285 5.392760276794434 18.44250106811523 6.624240398406982 18.44250106811523 7.917300224304199 C 18.44250106811523 10.72895050048828 16.1861400604248 13.01640033721924 13.41270065307617 13.01640033721924 Z"
                      stroke="none"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
              <span>Upload List</span>
            </router-link>
          </div>
        </div> -->
    </header>

    <div class="container-lg">
      <nav class="listNav">
        <div class="listNav__text d-flex align-items-center">
          <button
            class="list-filter-toggler d-xl-none mr-2"
            @click="sidebarOpen = !sidebarOpen"
          >
            <i class="fas fa-bars"></i>
          </button>
          <p class="txt__md font-weight-bold">Filter</p>
        </div>
        <div class="listNav__filter d-flex align-items-center">
          <p class="txt__xs mr-4 current-list-total">
            <strong>{{ pagination.to }}</strong> of
            <strong>{{ pagination.total }}</strong> results
          </p>
          <!-- <p class="txt__xs mr-4 current-list-total">1 - 24 of 94 results</p> -->
          <select
            class="custom-select custom-select--list"
            v-model="sort"
            @change="sortProduct"
          >
            <option selected value="">Sort By</option>
            <option value="asc">Price low to high</option>
            <option value="desc">Price high to low</option>
          </select>
        </div>
      </nav>
      <div
        class="list__page"
        :style="{ 'min-height': filterBarHeight + 30 + 'px' }"
        :class="{ 'sidebar-open': sidebarOpen }"
      >
        <aside
          class="list__siderbar sticky-top top-offset"
          ref="filterBar"
          :class="sidebarOpen ? 'active' : ''"
        >
          <div class="d-flex justify-content-end d-xl-none pr-4 pt-3">
            <button class="list-filter-toggler" @click="sidebarOpen = false">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="filer-box bg-white py-3 position-relative">
            <label class="txt__sm font-weight-bold mb-2">Search</label>
            <input
              type="search"
              class="form-control"
              :class="[search.length > 0 ? '' : 'form-control-search']"
              placeholder="Search by"
              v-model="search"
            />
          </div>
          <div class="filer-box bg-white py-3">
            <div class="range-slider-wrapper">
              <VueSlider
                v-model="priceRange"
                :enable-cross="false"
                :min-range="1"
                :max="50000"
                :interval="1"
                v-bind="{ ...rangeOptions }"
              ></VueSlider>
            </div>

            <div class="d-flex justify-content-between mt-2">
              <p class="txt__xs">
                Min: <span>{{ priceRange[0] }}</span>
              </p>
              <p class="txt__xs">
                Max: <span>{{ priceRange[1] }}</span>
              </p>
            </div>
          </div>
          <div class="filer-box bg-white py-3">
            <label class="txt__sm font-weight-bold mb-2">Date</label>
            <VueCtkDateTimePicker
              v-model="startDate"
              id="startdate"
              class="custom-date-picker mb-3"
              color="#ea4639"
              :onlyDate="true"
              :noButtonNow="true"
              :label="'Start date'"
            />
            <VueCtkDateTimePicker
              v-model="endDate"
              id="enddate"
              class="custom-date-picker"
              color="#ea4639"
              :onlyDate="true"
              :noButtonNow="true"
              :label="'End date'"
            />
          </div>
          <div class="filer-box bg-white">
            <!-- <div
              class="industry-filter-button py-3"
              @click="openFilterModal()"
              :class="{ active: IndustryFilterModalOpen }"
            >
              <span class="txt__sm font-weight-bold">Industry</span>
              <span
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="7"
                  viewBox="0 0 4 7"
                >
                  <path
                    d="M3.5,0,7-4H0Z"
                    transform="translate(4 7) rotate(-90)"
                    fill="#0091ff"
                  /></svg
              ></span>
            </div> -->
            <span class="txt__sm font-weight-bold">Industry</span>
            <multiselect
              v-model="IndustryValue"
              :options="industries"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
            >
            </multiselect>
          </div>
          <div class="filer-box bg-white py-3">
            <label class="txt__sm font-weight-bold mb-2">Rating</label>

            <ul class="raling__lists">
              <li class="rating-items">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck1"
                    v-model="ratings"
                    value="5"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck1"
                  ></label>
                </div>
                <span class="txt__xs mx-2">5</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.364"
                  height="11.759"
                >
                  <defs>
                    <linearGradient
                      id="a"
                      x1="1"
                      x2=".316"
                      y2=".745"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#32c5ff" />
                      <stop offset=".212" stop-color="#e06220" />
                      <stop offset="1" stop-color="#ffd500" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Star Copy"
                    d="M6.5 9.75l-3.821 2.009.73-4.259L.318 4.491l4.272-.62L6.5 0l1.91 3.871 4.272.621L9.591 7.5l.73 4.254z"
                    transform="translate(-.318)"
                    fill="url(#a)"
                  />
                </svg>
              </li>
              <li class="rating-items">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck2"
                    v-model="ratings"
                    value="4"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck2"
                  ></label>
                </div>
                <span class="txt__xs mx-2">4</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.364"
                  height="11.759"
                >
                  <defs>
                    <linearGradient
                      id="a"
                      x1="1"
                      x2=".316"
                      y2=".745"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#32c5ff" />
                      <stop offset=".212" stop-color="#e06220" />
                      <stop offset="1" stop-color="#ffd500" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Star Copy"
                    d="M6.5 9.75l-3.821 2.009.73-4.259L.318 4.491l4.272-.62L6.5 0l1.91 3.871 4.272.621L9.591 7.5l.73 4.254z"
                    transform="translate(-.318)"
                    fill="url(#a)"
                  />
                </svg>
              </li>
              <li class="rating-items">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck3"
                    v-model="ratings"
                    value="3"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck3"
                  ></label>
                </div>
                <span class="txt__xs mx-2">3</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.364"
                  height="11.759"
                >
                  <defs>
                    <linearGradient
                      id="a"
                      x1="1"
                      x2=".316"
                      y2=".745"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#32c5ff" />
                      <stop offset=".212" stop-color="#e06220" />
                      <stop offset="1" stop-color="#ffd500" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Star Copy"
                    d="M6.5 9.75l-3.821 2.009.73-4.259L.318 4.491l4.272-.62L6.5 0l1.91 3.871 4.272.621L9.591 7.5l.73 4.254z"
                    transform="translate(-.318)"
                    fill="url(#a)"
                  />
                </svg>
              </li>
              <li class="rating-items">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck4"
                    v-model="ratings"
                    value="2"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck4"
                  ></label>
                </div>
                <span class="txt__xs mx-2">2</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.364"
                  height="11.759"
                >
                  <defs>
                    <linearGradient
                      id="a"
                      x1="1"
                      x2=".316"
                      y2=".745"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#32c5ff" />
                      <stop offset=".212" stop-color="#e06220" />
                      <stop offset="1" stop-color="#ffd500" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Star Copy"
                    d="M6.5 9.75l-3.821 2.009.73-4.259L.318 4.491l4.272-.62L6.5 0l1.91 3.871 4.272.621L9.591 7.5l.73 4.254z"
                    transform="translate(-.318)"
                    fill="url(#a)"
                  />
                </svg>
              </li>
              <li class="rating-items">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck5"
                    v-model="ratings"
                    value="1"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck5"
                  ></label>
                </div>
                <span class="txt__xs mx-2">1</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.364"
                  height="11.759"
                >
                  <defs>
                    <linearGradient
                      id="a"
                      x1="1"
                      x2=".316"
                      y2=".745"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#32c5ff" />
                      <stop offset=".212" stop-color="#e06220" />
                      <stop offset="1" stop-color="#ffd500" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Star Copy"
                    d="M6.5 9.75l-3.821 2.009.73-4.259L.318 4.491l4.272-.62L6.5 0l1.91 3.871 4.272.621L9.591 7.5l.73 4.254z"
                    transform="translate(-.318)"
                    fill="url(#a)"
                  />
                </svg>
              </li>
            </ul>
          </div>
          <div class="filer-box bg-white py-3">
            <label class="txt__sm font-weight-bold mb-2">Download</label>
            <div class="range-slider-wrapper">
              <VueSlider
                v-model="downloadRange"
                :enable-cross="false"
                :min-range="1"
                :max="50000"
                :interval="1"
                v-bind="{ ...rangeOption }"
              ></VueSlider>
            </div>

            <div class="d-flex justify-content-between mt-2">
              <p class="txt__xs">
                Min: <span>{{ downloadRange[0] }}</span>
              </p>
              <p class="txt__xs">
                Max: <span>{{ downloadRange[1] }}</span>
              </p>
            </div>
            <div class="d-flex justify-content-end mt-4">
              <button
                class="btn-wrap btn-primary btn-sm text-white"
                @click="getProducts"
              >
                Apply
              </button>
              <button
                class="btn-wrap btn-danger btn-sm text-white ml-2"
                @click="clearFilter"
              >
                Reset
              </button>
            </div>
          </div>
        </aside>
        <div class="lists">
          <div class="row-wrapper">
            <div class="row">
              <div
                class="col-lg-6 col-md-6 col-sm-12 mb-4"
                v-for="(list, index) in Lists"
                :key="index"
              >
                <div
                  class="product__card h-100"
                  :class="{ 'product__card--recommend': list.recommend }"
                >
                  <div class="product__card__header">
                    <router-link
                      :to="{
                        path: '/marketplace-details',
                        query: { product: list.id },
                      }"
                    >
                      <p class="txt__md font-weight-bold txt overflow-text">
                        {{ list.title }}
                      </p></router-link
                    >

                    <div
                      class="product__card__tags"
                      v-if="list.industries.length > 0"
                    >
                      <a
                        href="javascript:void(0);"
                        class="product-tag"
                        v-for="(industry, j) in list.industries"
                        :key="j"
                      >
                        {{ industry }}
                      </a>
                    </div>
                  </div>

                  <div class="product__card__review">
                    <div class="product__card__reviews mb-2">
                      <Rating v-bind:average_rating="list.average_rating" />
                      <p class="txt__sm">{{ list.total_review }} reviews</p>
                    </div>
                    <div class="product__card__reviews__update mb-2">
                      <p class="txt__xs mb-2 text-muted">Updated on</p>
                      <p class="txt__xs">{{ formatDate(list.updated_at) }}</p>
                      <!-- <p class="txt__xs">{{ list.updated_at }}</p> -->
                    </div>
                  </div>

                  <div class="product__card__stats">
                    <div class="form-row">
                      <div class="col mb-2">
                        <p class="txt__lg font-weight-bold mb-2">
                          {{ list.row_count }}
                        </p>
                        <div class="d-flex align-items-center">
                          <svg
                            width="13"
                            height="14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="6.281"
                              cy="2.844"
                              r="2.844"
                              fill="#0091FF"
                            />
                            <path
                              d="M1 13c0-2.692 2.365-4.875 5.281-4.875 2.917 0 5.282 2.183 5.282 4.875"
                              stroke="#0091FF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <p class="txt__xs ml-2">Data</p>
                        </div>
                      </div>
                      <div class="col mb-2">
                        <p class="txt__lg font-weight-bold mb-2">
                          {{ list.view }}
                        </p>
                        <div class="d-flex align-items-center">
                          <svg
                            width="21"
                            height="15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.996 7.31a.507.507 0 00-.72 0 .512.512 0 000 .722c.2.2.522.2.72 0a.512.512 0 000-.723z"
                              fill="#0091FF"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.164 9.204c0-.564.456-1.022 1.018-1.022a1.022 1.022 0 010 2.045 1.022 1.022 0 01-1.018-1.023zm.678 0c0 .188.153.341.34.341a.34.34 0 000-.682.34.34 0 00-.34.341z"
                              fill="#0091FF"
                            />
                            <path
                              d="M10.182 6.137c-.427 0-.84.086-1.227.257a.384.384 0 00-.196.506c.085.194.31.282.504.196.29-.127.599-.192.919-.192a2.299 2.299 0 012.29 2.3 2.299 2.299 0 01-2.29 2.302A2.299 2.299 0 017.89 9.205v-.04a.382.382 0 10-.763-.018v.058a3.065 3.065 0 003.054 3.068 3.065 3.065 0 003.054-3.068 3.065 3.065 0 00-3.054-3.068zM10.69 0c-.28 0-.508.172-.508.384v2.3c0 .212.228.384.509.384s.509-.172.509-.383V.384c0-.212-.228-.384-.51-.384zM6.014 3.532L4.63 2.142a.325.325 0 00-.462 0 .329.329 0 000 .463l1.384 1.39a.325.325 0 00.462 0 .329.329 0 000-.463zM16.195 2.142a.325.325 0 00-.461 0l-1.384 1.39a.329.329 0 000 .463.325.325 0 00.462 0l1.383-1.39a.329.329 0 000-.463z"
                              fill="#0091FF"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.182 4.09c3.804 0 7.555 2.353 10.082 5.182a.39.39 0 01-.035.555.4.4 0 01-.561-.034 16.74 16.74 0 00-.26-.284c-1.965 2.937-5.528 4.81-9.226 4.81-3.699 0-7.261-1.873-9.227-4.81-.088.094-.174.189-.26.284a.4.4 0 01-.56.034.39.39 0 01-.035-.555C2.626 6.443 6.377 4.09 10.182 4.09zM1.524 8.93c1.798 2.805 5.161 4.601 8.658 4.601 3.496 0 6.86-1.796 8.658-4.601-1.417-1.373-4.691-4.052-8.658-4.052-3.967 0-7.241 2.679-8.658 4.052z"
                              fill="#0091FF"
                            />
                          </svg>
                          <p class="txt__xs ml-2">View</p>
                        </div>
                      </div>
                      <div class="col mb-2">
                        <p class="txt__lg font-weight-bold mb-2">
                          {{ list.download }}
                        </p>
                        <div class="d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-cloud-arrow-down text-primary"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"
                            />
                            <path
                              d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"
                            />
                          </svg>
                          <p class="txt__xs ml-2">Download</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="product__card__uploader">
                    <div class="product__uploader">
                      <router-link
                        :to="{
                          path: '/user-profile',
                          query: { profile: list.seller_id },
                        }"
                      >
                        <div class="avatar">
                          {{ firstChar(list.seller_name) | capitalize }}
                        </div>
                      </router-link>
                      <router-link
                        :to="{
                          path: '/user-profile',
                          query: { profile: list.seller_id },
                        }"
                      >
                        <div>
                          <p class="txt__10">Uploaded by</p>
                          <p class="txt__sm txt">{{ list.seller_name }}</p>
                        </div>
                      </router-link>
                    </div>
                    <button
                      class="product__cart__btn"
                      title="add to cart"
                      @click="addToCart(index)"
                      v-if="!list.owned"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="23"
                        fill="currentColor"
                        class="bi bi-cart-plus"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z"
                        />
                        <path
                          d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                        />
                      </svg>
                    </button>
                    <button
                      :class="[
                        'product__purchase__btn',
                        list.purchased ? 'sold' : '',
                      ]"
                      @click="getpurchasemodalopen(list.id, index)"
                    >
                      <svg
                        width="20"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.281 3.008C6.281 1.054 9.68 0 12.877 0s6.595 1.054 6.595 3.008V15.04c0 1.734-2.685 2.734-5.346 2.958l-.051.002a.6.6 0 01-.05-1.2c2.773-.233 4.248-1.171 4.248-1.76v-.628c-.698.446-1.674.8-2.893 1.015a.6.6 0 01-.209-1.184c2.016-.357 3.102-1.095 3.102-1.61v-.627c-.698.446-1.674.8-2.893 1.015a.6.6 0 01-.209-1.184c2.016-.357 3.102-1.096 3.102-1.61V9.6c-.698.448-1.674.8-2.893 1.016a.6.6 0 01-.209-1.184c2.016-.358 3.102-1.096 3.102-1.61v-.644c-1.03.658-2.585 1.064-4.147 1.195h-.005l-.046.002a.601.601 0 01-.05-1.2c2.773-.233 4.248-1.171 4.248-1.76v-.626c-1.272.805-3.373 1.228-5.396 1.228-2.023 0-4.124-.423-5.396-1.228v.626a.6.6 0 11-1.2 0V3.008zm1.2 0c0 .735 2.102 1.805 5.396 1.805s5.396-1.07 5.396-1.805c0-.735-2.102-1.805-5.396-1.805S7.48 2.273 7.48 3.008z"
                          fill="#fff"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 10.38c0-1.905 3.398-2.932 6.595-2.932 3.197 0 6.596 1.027 6.596 2.931v4.69C13.19 16.973 9.792 18 6.595 18S0 16.973 0 15.069v-4.69zm6.595 6.448c3.295 0 5.397-1.043 5.397-1.76v-.61c-1.273.784-3.374 1.197-5.397 1.197s-4.124-.413-5.396-1.197v.61c0 .717 2.102 1.76 5.396 1.76zm5.397-4.104c0 .716-2.102 1.759-5.397 1.759-3.294 0-5.396-1.043-5.396-1.759v-.61c1.272.784 3.373 1.196 5.396 1.196 2.023 0 4.124-.412 5.397-1.197v.611zM1.199 10.38c0 .717 2.102 1.759 5.396 1.759 3.295 0 5.397-1.042 5.397-1.759 0-.716-2.102-1.758-5.397-1.758-3.294 0-5.396 1.042-5.396 1.758z"
                          fill="#fff"
                        />
                      </svg>

                      {{ !list.purchased ? list.price + "cr" : "Purchased" }}
                    </button>
                  </div>

                  <div class="product__card--ribbon" v-if="list.owned">
                    Owned
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              class="full-width-action-btn"
              v-if="load_more && !loading"
              @click="paginate"
            >
              Load More
            </button>

            <button
              class="spin-wrap bg-white border-none out w-100"
              v-if="loading"
            >
              <i class="fas fa-circle-notch fa-spin"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL -->
    <Dialog />
    <!-- <IndustryFilterModal /> -->
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import Multiselect from "vue-multiselect";
// import IndustryFilterModal from "@/components/modal/IndustryFIlter";
import Rating from "@/components/common/Rating";
import Dialog from "@/components/modal/Dialog";
import { axiosP, axiosHeaderP } from "@/services/axiosinstance";

export default {
  name: "marketplace",
  components: {
    Multiselect,
    VueSlider,
    Rating,
    Dialog,
    // IndustryFilterModal,
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      priceRange: [0, 50000],
      downloadRange: [0, 1000000],
      ratings: [],
      rangeOptions: {
        dotSize: 19,
        height: 1,
      },
      pagination: {
        to: "",
        total: "",
      },
      page: 1,
      load_more: false,
      loading: false,
      rangeOption: {
        dotSize: 19,
        height: 1,
      },
      search: "",
      sort: "",
      Lists: {},
      sidebarOpen: false,
      IndustryFilterModalOpen: false,
      filterBarHeight: 700,
      IndustryValue: [],
      industries: [],
    };
  },
  created() {
    this.getProducts();
    this.getIndustries();
  },
  mounted() {
    this.getSidebarheight();
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  watch: {
    search: function (newVal, oldVal) {
      if (newVal.trim() != oldVal.trim()) {
        this.page = 1;
        this.Lists = [];
        this.getProducts();
      }
    },
  },
  methods: {
    sortProduct() {
      this.page = 1;
      this.getProducts();
    },

    addToCart(index) {
      let product = this.Lists[index];

      let result = this.$helpers.addToCart(product);
      if (result == "exists") {
        this.$toasted.show("item already added on your cart !", {
          className: "succes-class",
        });
        return;
      }
      if (result == "success") {
        this.$toasted.show("Added to cart!", {
          className: "succes-class",
        });
        return;
      }
    },

    clearFilter() {
      this.search = "";
      this.priceRange = ["", ""];
      this.startDate = "";
      this.endDate = "";
      this.downloadRange = ["", ""];
      this.ratings = [];
      this.IndustryValue = [];
      this.getProducts();
    },
    // temp(a) {
    //   var notreturn = 5 - parseInt(a);
    //   return notreturn;
    // },
    firstChar(seller) {
      if (seller) {
        return seller.charAt(0);
      } else {
        return;
      }
    },
    formatDate(date) {
      if (date) {
        return this.$moment(date).format("YYYY-MM-DD");
      } else {
        return "N/A";
      }
    },
    async getProducts() {
      this.formData = new FormData();
      this.formData.append("page", this.page);
      this.formData.append("limit", 4);
      this.formData.append("search", this.search);
      this.formData.append("sort_order", this.sort);
      this.formData.append("sort_by", "price");
      this.formData.append("min_price", this.priceRange[0]);
      this.formData.append("max_price", this.priceRange[1]);
      this.formData.append("startDate", this.startDate);
      this.formData.append("endDate", this.endDate);
      this.formData.append("industries", this.IndustryValue);
      this.formData.append("min_download", this.downloadRange[0]);
      this.formData.append("max_download", this.downloadRange[1]);
      this.formData.append("rating", this.ratings.join());
      var instance = axiosP;

      if (this.$store.getters.getLoggedIn) {
        instance = axiosHeaderP;
      }
      await instance
        .post("products", this.formData)
        .then((res) => {
          if (res.data.status) {
            this.Lists = res.data.data;
            this.load_more = res.data.pagination.load_more;
            this.pagination = res.data.pagination;
          } else {
            this.Lists = [];
            this.load_more = false;
          }
        })
        .catch(() => {
          this.Lists = [];
          this.load_more = false;
          // console.log(err);
        });
    },
    async paginate() {
      this.loading = true;
      // this.getProducts();
      this.page++;
      this.formData = new FormData();
      this.formData.append("page", this.page);
      this.formData.append("limit", 4);
      this.formData.append("search", this.search);
      this.formData.append("sort_order", this.sort);
      this.formData.append("sort_by", "price");
      this.formData.append("min_price", this.priceRange[0]);
      this.formData.append("max_price", this.priceRange[1]);
      this.formData.append("startDate", this.startDate);
      this.formData.append("endDate", this.endDate);
      this.formData.append("industries", this.IndustryValue);
      this.formData.append("min_download", this.downloadRange[0]);
      this.formData.append("max_download", this.downloadRange[1]);
      this.formData.append("rating", this.ratings.join());
      var instance = axiosP;

      if (this.$store.getters.getLoggedIn) {
        instance = axiosHeaderP;
      }
      await instance
        .post("products", this.formData)
        .then((res) => {
          if (res.data.status) {
            let Lists = res.data.data;
            Lists.map((val) => {
              this.Lists.push(val);
            });
            this.load_more = res.data.pagination.load_more;
            this.pagination = res.data.pagination;
          } else {
            this.load_more = false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.Lists = [];
          this.loading = false;
          // console.log(err);
        });
    },
    async getIndustries() {
      this.formData = new FormData();
      await axiosP
        .post("all-industry-list", this.formData)
        .then((res) => {
          this.industries = res.data.industries;
        })
        .catch(() => {
          // console.log(err);
        });
    },
    openFilterModal() {
      this.$modal.show("industryFilterModal");
    },
    openListModal() {
      // this.$modal.show("listUploadModal");
    },
    getSidebarheight() {
      const SIDEBAR_HEIGHT = this.$refs.filterBar.clientHeight;
      this.filterBarHeight = SIDEBAR_HEIGHT;
    },

    getpurchasemodalopen(id, index) {
      this.$modal.show("Dialog", {
        title: "Are you sure?",
        text: "Do you want to purchase this list?",
        buttons: [
          {
            title: "No",
            handler: () => {
              this.$modal.hide("Dialog");
            },
          },
          {
            title: "Yes",
            handler: () => {
              this.purchase(id, index);
            },
          },
        ],
      });
    },

    async purchase(id, index) {
      if (this.$store.getters.getLoggedIn) {
        this.formData = new FormData();
        this.formData.append("product_id", id);
        await axiosHeaderP
          .post("purchase", this.formData)
          .then((res) => {
            if (res.data.status) {
              // this.profile();
              this.$store.dispatch(
                "setUserCredit",
                res.data.data.current_credit
              );

              this.$toasted.show(res.data.message, {
                icon: "check",
                className: "succes-class",
              });
              this.$modal.hide("Dialog");
              this.Lists[index].purchased = true;

              // window.location.reload();
            }
            //  console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
            this.$toasted.show(err.response.data.message, {
              icon: "times-circle",
              className: "error-class",
            });
            this.$modal.hide("Dialog");
          });
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-wrap {
  padding: 5px 23px;
}
.spin-wrap {
  outline: none;
  border: none;
  padding: 10px 0;
}
.fa-spin {
  color: #007bff;
  font-size: 30px;
}
.clear__input[data-v-0daac4f0] {
  right: 28px;
  top: 53px;
  border: none;
  background: none;
}
.list__siderbar {
  box-shadow: 0px 0px 18px 0px #80808029;
}
.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.toasting {
  color: yellow !important;
  background-color: pink !important;
}
@media (min-width: 1200px) {
}
@media (min-width: 992px) {
}
@media (min-width: 768px) {
}
@media (min-width: 576px) {
}
</style>
