<template>
  <div class="product__reviews">
    <span
      class="product__review__stars"
      v-for="rating in average_rating"
      :key="rating.id"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="14.632" height="13.916">
        <g data-name="Star Copy 2" fill="#fff" stroke-miterlimit="10">
          <path
            d="M11.174 13.002l-3.625-1.906-.233-.122-.232.122-3.625 1.906.692-4.037.045-.259-.189-.183-2.932-2.859 4.052-.588.26-.038.117-.236L7.316 1.13 9.13 4.802l.116.236.26.038 4.053.588-2.933 2.859-.188.183.044.26.693 4.036z"
          />
          <path
            d="M10.51 12.088L9.989 9.05l-.09-.518.377-.367 2.207-2.152-3.05-.443-.52-.075-.233-.472L7.316 2.26 5.952 5.023l-.232.472-.52.075-3.05.443 2.206 2.152.377.367-.089.518-.521 3.037 2.728-1.434.465-.244.466.244 2.728 1.435m1.328 1.828l-4.522-2.378-4.521 2.378.863-5.035L0 5.315l5.056-.734L7.316 0l2.261 4.58 5.055.735-3.658 3.566.864 5.035z"
            fill="#0091ff"
          />
        </g>
      </svg>
    </span>
    <span
      class="product__review__stars not-stared"
      v-for="notrating in temp(average_rating)"
      :key="notrating"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="14.632" height="13.916">
        <g data-name="Star Copy 2" fill="#fff" stroke-miterlimit="10">
          <path
            d="M11.174 13.002l-3.625-1.906-.233-.122-.232.122-3.625 1.906.692-4.037.045-.259-.189-.183-2.932-2.859 4.052-.588.26-.038.117-.236L7.316 1.13 9.13 4.802l.116.236.26.038 4.053.588-2.933 2.859-.188.183.044.26.693 4.036z"
          />
          <path
            d="M10.51 12.088L9.989 9.05l-.09-.518.377-.367 2.207-2.152-3.05-.443-.52-.075-.233-.472L7.316 2.26 5.952 5.023l-.232.472-.52.075-3.05.443 2.206 2.152.377.367-.089.518-.521 3.037 2.728-1.434.465-.244.466.244 2.728 1.435m1.328 1.828l-4.522-2.378-4.521 2.378.863-5.035L0 5.315l5.056-.734L7.316 0l2.261 4.58 5.055.735-3.658 3.566.864 5.035z"
            fill="#0091ff"
          />
        </g>
      </svg>
    </span>
  </div>
</template>

<script>
export default {
    name: "Rating",
    props:{
      average_rating: Number,
    },
    methods: {
    temp(a) {
      var notreturn = 5 - parseInt(a);
      return notreturn;
    },
    },
};
</script>

<style>
</style>