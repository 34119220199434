<template>
  <main class="main">
    <Navbar />
    <router-view />
    <Footer />
  </main>
</template>

<script >
import Navbar from "@/components/layout/Navbar";
import Footer from "@/components/layout/Footer";
export default {
  components: { Navbar, Footer},

  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
</style>
