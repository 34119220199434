<template>
  <footer class="footer">
    <div class="container-lg">
      <div class="footer__text">
        <p class="footer__txt txt__sm">© CompilerX. All Rights Reserved</p>

        <ul class="terms">
          <li>
            <a href="javascript:void(0);">Terms of Service Privacy Policy</a>
          </li>
        </ul>

        <div class="social__links">
          <a href="javascript:void(0);" target="_blank" class="social__link"
            ><i class="fab fa-facebook-square"></i>
          </a>
          <a href="javascript:void(0);" target="_blank" class="social__link"
            ><i class="fab fa-twitter-square"></i>
          </a>
          <a href="javascript:void(0);" target="_blank" class="social__link"
            ><i class="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

