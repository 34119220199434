import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "jquery/src/jquery.js";
import "popper.js/dist/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/fontawesome/css/all.css";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "@/assets/scss/main.scss";
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
import VModal from "vue-js-modal";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import Toasted from "vue-toasted";
import HighchartsVue from "highcharts-vue";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {axiosL, axiosP, axiosHeaderL, axiosHeaderP} from "./services/axiosinstance";

import helpers from './assets/js/helpers'

const plugin = {
  install () {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
  }
}

Vue.use(plugin)



Vue.use( CKEditor );
Vue.use(HighchartsVue);
Vue.use(VModal);
Vue.config.productionTip = false;
Vue.use(VueMoment, {
  moment
});

let options = {
  iconPack: "fontawesome",
  fitToScreen: true,
  position: "top-center",
  duration: 3000,
  keepOnHover: true,
  singleton: true,
  closeOnSwipe: true,
  fullWidth: false,
  dismissable: true,
  action: {
      icon: "times",
      class: "action-class",
      onClick: (e, toastObject) => {
          toastObject.goAway(0);
      }
  }
};
Vue.use(Toasted, options);

Vue.filter("formatDate", function(value) {
  if (value) {
      return moment(String(value)).format("MM/DD/YYYY hh:mm A");
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

window.axiosL = axiosL
window.axiosP = axiosP
window.axiosHeaderL = axiosHeaderL
window.axiosHeaderP = axiosHeaderP
