<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <div class="container-lg">
      <!-- <a class="navbar-brand order-1" href="/"> -->
      <router-link title="dashboard" to="/" class="navbar-brand order-1">
        <div class="navbar-logo">
          <img
            class="d-none d-sm-inline-block"
            src="@/assets/images/nav-logo.png"
            alt="Compilerx Logo"
          />
          <img
            class="d-sm-none"
            src="@/assets/images/nav-logo-mobile.png"
            alt="Compilerx Logo"
          />
        </div>
      </router-link>
      <!-- </a> -->
      <button
        class="navbar-toggler order-3 order-lg-2 ml-3"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse order-4 order-lg-3"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link title="Dashboard" to="/" class="nav-link mt-2">
              Home
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);">Company Data</a>
          </li> -->
          <li class="nav-item">
            <!-- <a class="nav-link" href="javascript:void(0);">Email List</a> -->
            <router-link
              v-if="logged_in"
              title="Purchased List"
              to="/purchaseList"
              class="nav-link mt-2"
            >
              Purchased List
            </router-link>
          </li>

          
          <li class="nav-item">
            <!-- <a class="nav-link" href="/list-upload"
              >Email Verification</a
            > -->
            <router-link
              v-if="logged_in"
              title="list-upload"
              to="/list-upload"
              class="nav-link mt-2"
            >
              list upload
            </router-link>
          </li>
          <li class="nav-item">
            <!-- <router-link
              v-if="logged_in"
              :to="{
                path: '/user-profile',
                query: { profile: client_id },
              }"
              class="nav-link mt-2"

            >
              My account
            </router-link> -->
            <a
              v-if="logged_in"
              :href="'/user-profile?profile='+client_id"
              class="nav-link mt-2"

            >
              My account
            </a>
          </li>

          <li class="nav-item">
            <!-- <a class="nav-link" href="/list-upload"
              >Email Verification</a
            > -->
            <router-link title="AddCart" to="/addCart" class="nav-link">
              <button type="button" class="btn text-white position-relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="25"
                  fill="currentColor"
                  class="bi bi-cart-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                  />
                </svg>

                <span
                  class="
                    position-absolute
                    top-0
                    start-100
                    translate-middle
                    p-2
                    bg-danger
                    border border-light
                    rounded-circle
                  "
                  id="cart-add-area"
                >
                  <span class="visually-hidden">{{
                    this.$store.getters.getCart.length
                  }}</span>
                </span>
              </button>
            </router-link>
          </li>
        </ul>
      </div>
      <div
        class="btn btn-white ml-auto ml-lg-3 order-2 order-lg-4"
        v-show="logged_in"
      >
        <svg
          id="coins"
          xmlns="http://www.w3.org/2000/svg"
          width="19.473"
          height="18"
          viewBox="0 0 19.473 18"
          class="me-2 position-relative"
        >
          <path
            id="Shape"
            d="M7.2,17.449a.6.6,0,0,1,.547-.65c2.772-.232,4.247-1.171,4.247-1.759v-.628A8.023,8.023,0,0,1,9.1,15.428a.526.526,0,0,1-.105.009.6.6,0,0,1-.1-1.193c2.016-.357,3.1-1.1,3.1-1.61v-.627A8.007,8.007,0,0,1,9.1,13.021a.528.528,0,0,1-.105.01.6.6,0,0,1-.1-1.194c2.016-.357,3.1-1.1,3.1-1.609V9.6A7.99,7.99,0,0,1,9.1,10.615a.528.528,0,0,1-.105.01.6.6,0,0,1-.1-1.193c2.016-.357,3.1-1.1,3.1-1.61V7.178A9.591,9.591,0,0,1,7.845,8.372l-.052,0a.6.6,0,0,1-.049-1.2c2.772-.232,4.247-1.171,4.247-1.76V4.788A10.855,10.855,0,0,1,6.6,6.016,10.855,10.855,0,0,1,1.2,4.788v.626a.6.6,0,1,1-1.2,0V3.008C0,1.054,3.4,0,6.6,0s6.6,1.054,6.6,3.008V15.04c0,1.734-2.686,2.734-5.346,2.957l-.052,0A.6.6,0,0,1,7.2,17.449Z"
            transform="translate(6.281)"
            fill="#214c6e"
          />
          <path
            id="Shape-2"
            data-name="Shape"
            d="M0,7.62V2.931C0,1.027,3.4,0,6.6,0s6.6,1.027,6.6,2.931V7.62c0,1.9-3.4,2.931-6.6,2.931S0,9.525,0,7.62Z"
            transform="translate(0 7.448)"
            fill="#214c6e"
          />
        </svg>
        <!-- <span
          class="
            position-absolute
            top-0
            start-100
            translate-middle
            p-2
            bg-danger
            border border-light
            rounded-circle
          "
          id="notification-icon"
            >
          <span class="visually-hidden">{{
            this.$store.getters.getCart.length
          }}</span>
        </span> -->
        {{ $store.getters.getUserCredit }} Cr
      </div>
      <router-link
        class="btn btn-white btn-lg ml-auto ml-lg-3 order-2 order-lg-4"
        v-if="!logged_in"
        title="login"
        to="/login"
      >
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="me-3"
        >
          <circle
            cx="9.5"
            cy="9.5"
            r="9"
            transform="rotate(-90 9.5 9.5)"
            stroke="#214C6E"
          />
          <path d="M9.49999 12.6667V0" stroke="#214C6E" />
        </svg>
        Login
      </router-link>
      <a
        href="javscript:void(0);"
        class="btn btn-white btn-lg ml-auto ml-lg-3 order-2 order-lg-4"
        v-if="logged_in"
        @click="logout()"
      >
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="me-3"
        >
          <circle
            cx="9.5"
            cy="9.5"
            r="9"
            transform="rotate(-90 9.5 9.5)"
            stroke="#214C6E"
          />
          <path d="M9.49999 12.6667V0" stroke="#214C6E" />
        </svg>

        Logout
      </a>
      <div class="dropdown d-flex justify-content-end order-lg-4">
        <button
          class="btn text-white"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            class="bi bi-bell-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"
            />
          </svg>
          <span
            class="
              position-absolute
              top-0
              start-100
              translate-middle
              p-2
              bg-danger
              border border-light
              rounded-circle
            "
            id="notification-icon"
          >
            <span
              class="visually-hidden"
              v-if="this.$store.getters.getNotification.length <= 9"
              >{{ this.$store.getters.getNotification.length }}</span
            >
            <span v-else class="visually-hidden">9+</span>
          </span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <div class="text-number d-flex justify-content-end">
            <a
              href="#"
              class="txt__small txt__light d-flex justify-content-end pr-2 pr-2"
              >Mark all as read</a
            >
            <a
              href="#"
              class="txt__small txt__light d-flex justify-content-end pr-3 pr-3"
              >Clear All</a
            >
          </div>
          <ul class="pt-2 pb-2 pr-4 pl-5">
            <li
              class="notification__item bg-white"
              v-for="notification in this.$store.getters.getNotification"
              :key="notification.id"
            >
              <div class="pagination-link cursor-pointer">
                <div class="d-flex align-items-center">
                  <div class="dottted-icon"></div>
                  <div class="mr-2 icon-wrap">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#6f7e90"
                      class="bi bi-bell-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"
                      />
                    </svg>
                  </div>
                  <div class="w-100">
                    <p class="para mb-1">
                      {{ notification.message }}
                    </p>
                    <p class="txt__small txt__light">04/08/2022 03:30 PM</p>
                  </div>
                </div>
                <div class="w-100 d-flex justify-content-end">
                  <a href="#" class="txt__small txt__light float-end">Clear</a>
                  <a href="#" class="txt__small txt__light me-2 float-end"
                    >Mark as read</a
                  >
                </div>
              </div>
            </li>
            <!-- <li class="notification__item pt-3 bg-white">
              <div class="pagination-link cursor-pointer">
                <div class="d-flex align-items-center">
                  <div class="mr-2 icon-wrap">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#6f7e90"
                      class="bi bi-bell-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"
                      />
                    </svg>
                  </div>
                  <div class="w-100">
                    <p class="para mb-1">
                      New S Test's trial period has expired!
                    </p>
                    <p class="txt__small txt__light">
                      04/08/2022 03:30 PM
                    </p>
                  </div>                  
              </div>
              <div class="w-100 d-flex justify-content-end">
                      <a href="#" class="txt__small txt__light float-end">Clear</a> 
                      <a href="#" class="txt__small txt__light me-2 float-end">Mark as read</a></div>                
                    </div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { axiosHeaderL } from "@/services/axiosinstance";

export default {
  name: "Navabar",
  data() {
    return {
      user: [],
      client_id: "",
    };
  },
  created() {
    this.client_id = localStorage.getItem("uid");
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("api_token") &&
      localStorage.getItem("token") != "" &&
      localStorage.getItem("api_token") != ""
    ) {
      this.$store.dispatch("setLoggedIn", true);
      this.profile();
    }
    this.initiateCart();
    return "";
  },
  computed: {
    logged_in() {
      return this.$store.getters.getLoggedIn;
    },
  },

  watch: {
    logged_in(newValue) {
      if (newValue) {
        this.profile();
      }
    },
  },
  methods: {

    initiateCart() {
      let cart = localStorage.getItem("cart");
      if (cart) {
        cart = JSON.parse(cart);
        for (let i = 0; i < cart.length; i++) {
          this.$store.dispatch("updateCart", cart[i]);
        }
      } else {
        if (this.$store.getters.getLoggedIn) {
          this.$helpers.getCart();
          this.$helpers.getNotifications(1);
        }
      }
    },

    async profile() {
      await axiosHeaderL
        .post("/api/auth/user", {
          device_type: 1,
          type: null,
        })
        .then((res) => {
          if (res.data.status) {
            // localStorage.setItem('user',res.data.data.id);
            this.user = res.data.data;
            this.$store.dispatch("setUserCredit", this.user.credits);
          } else {
            this.$toasted.show("Session expired!", {
              icon: "times-circle",
              className: "error-class",
            });
            localStorage.clear();
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.show("Session expired!", {
            icon: "times-circle",
            className: "error-class",
          });
          localStorage.clear();
          this.$router.push("/login");
        });
    },
    logout() {
      axiosHeaderL
        .post("/api/auth/logout")
        .then(() => {
          localStorage.clear();
          this.$router.push("/");
          this.$store.dispatch("setLoggedIn", false);
          this.$store.state.cart = [];
        })
        .catch(() => {
          localStorage.clear();
          this.$router.push("/");
          this.$store.dispatch("setLoggedIn", false);
          this.$store.state.cart = [];
        });
    },
  },
};
</script>

<style class="scss" scoped>
.dottted-icon {
  content: "";
  width: 5px;
  height: 5px;
  top: 69px;
  left: 13px;
  position: absolute;
  background: #0091ff;
  border-radius: 50%;
}
a.txt__small.txt__light.float-end {
  font-size: 11px;
  margin-right: 10px;
  padding-top: 10px;
}
p.para.mb-1 {
  font-size: 14px;
}
p.txt__small.txt__light {
  font-size: 12px;
  line-height: 10px;
}
.dropdown-menu {
  position: absolute;
  top: 126%;
  left: -101px;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 20rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.text-number {
  font-size: 12px;
  padding: 10px 0;
}
#cart-add-area {
  width: 23px;
  left: 7px;
  height: 23px;
  line-height: 13px;
  top: 1px;
}
#notification-icon {
  width: 23px;
  left: 7px;
  height: 23px;
  line-height: 13px;
  top: 1px;
}
.visually-hidden {
  font-size: 10px;
  line-height: -12px !important;
  top: 4px;
  position: absolute;
  left: 7px;
  color: black;
  color: #fff;
}
a.router-link-exact-active {
  color: #2bff00 !important;
}
</style>