import { axiosHeaderP } from "@/services/axiosinstance";
import store from "@/store"

export default {

    isExistCart(id) {
        let cart = store.getters.getCart;

        for (let i = 0; i < cart.length; i++) {
          if (cart[i].product_id == id) {
            return true;
          }
        }
        return false;
      },

    addToCart(product) {
        let exists = this.isExistCart(product.id);
        if (exists){
            return "exists"
        }
        else{
            let newData = {
                product_id: product.id,
                title: product.title,
                price: product.price,
                seller: product.seller,
                industries: product.industries,
                row_count: product.row_count,
              };
            
            if (store.getters.getLoggedIn){
                let formData = new FormData();
                formData.append("product_ids", product.id);
                axiosHeaderP
                    .post("add-to-cart", formData)
                    .then((res) => {
                        res
                        store.dispatch("updateCart", newData);
                    })
                    .catch(() => {
                        
                    });
            }
            else{
                store.dispatch("updateCart", newData);
                localStorage.setItem("cart", JSON.stringify(store.getters.getCart));
            }
            return "success"
        }
    },

    getCart(){

        axiosHeaderP
               .post("get-cart", this.formData)
               .then((res) => {
                 this.carts = res.data.data;
                 store.state.cart = this.carts
               })
               .catch(() => {
                 
               });
    },

    getNotifications(page){
      let formData = new FormData();
      formData.append("page", page)
      axiosHeaderP.post("get-notifications", formData)
      .then((res) => {
        store.dispatch("updateNotification", res.data.data)
      })
    }
}